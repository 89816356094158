@tailwind base;
@tailwind components;
@tailwind utilities;

@import '@shared/design-system/styles/initial.css';

@layer base {
  :root {
    /* Global */

    --max-content: 1296px;

    /* Colors */
    --color-neutral-0: 0 0% 13%; /* #222222 */
    --color-neutral-50: 0 0% 27%; /* #454545 */
    --color-neutral-100: 0 0% 60%; /* #999999 */
    --color-neutral-200: 0 0% 83%; /* #d4d4d4 */
    --color-neutral-300: 0 0% 92%; /* #ebebeb */
    --color-neutral-400: 0 0% 96%; /* #f5f5f5 */
    --color-neutral-500: 0 0% 99%; /* #fcfcfc */
    --color-neutral-600: 0 0% 100%; /* #ffffff */

    --color-orange-0: #e54c07;
    --color-orange-50: #f37c10;

    /* Fonts */
    --font-size-xs: 0.75rem;
    --font-size-sm: 0.875rem;
    --font-size-md: 1rem;
    --font-size-lg: 1.125rem;
    --font-size-xl: 1.25rem;
    --font-size-2xl: 1.5rem;
    --font-size-3xl: 2.5rem;

    /* Font weights */
    --font-weight-medium: 500;

    /* Button */
    --btn-text: hsl(var(--font-size-md));
    --btn-font-family: var(--font-secondary);
    --btn-font-weight: var(--font-weight-medium);
    --btn-primary-bg: hsl(var(--color-neutral-0));
    --btn-primary-hover: hsl(var(--color-neutral-50));

    --btn-secondary-bg: transparent;
    --btn-secondary-text: var(--color-neutral-0);
    --btn-secondary-hover: hsl(var(--color-neutral-400));
    --btn-secondary-active: hsl(var(--color-neutral-400));

    /* Card */

    --card-radius: 2.5rem;
    --card-border: none;
    --card-shadow: none;
  }
}
